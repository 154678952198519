<script setup lang="ts">
const props = defineProps({
  message: String,
});


</script>

<template>
  <div class="ys-error-warning w-full flex items-center">
    <Icon size="16" class="" name="ri:error-warning-fill"/>
    <p class="text-xs text-zinc-900">{{ props.message }}</p>
  </div>

</template>